.star-rating {
  display: inline-block;
}

.star-container {
  font-size: 0; /* Remove inline-block gap */
  cursor: pointer;
}

.star {
  font-size: 24px;
  display: inline-block;
  color: gray;
}

.filled {
  color: gold;
}

.partially-filled {
  color: gray;
  position: relative;
  display: inline-block;
}

.partially-filled::before {
  content: "★";
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 0;
  color: gold;
}

@for $i from 1 through 10 {
  $width: 10 * $i;
  .partially-filled-#{$width}::before {
    width: #{$width + "%"};
  }
}
