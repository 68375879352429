#service_list {
  .empty_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    i {
      font-size: 100px;
      color: #83899354;
    }
    h4 {
      font-size: 16px;
      font-weight: 700;
      margin-top: -15px;
    }
  }
  .tab_1 {
    width: 190px;
    max-width: 190px;
    .card {
      min-height: calc(100vh - 140px);
    }
    .custom_accordion {
      .accordion-body {
        padding: 0px 5px;
      }

      .service-ul {
        margin-bottom: 5px;
        li {
          color: #74788d;
          cursor: pointer;
          font-size: 13px;
          font-weight: 400;
          &.active {
            color: #e18479;
          }
        }
      }

      .accordion-button {
        background: transparent;
        box-shadow: none;
        font-weight: 600;
        padding: 8px 0;
        &::after {
          content: "\f0143";
          display: inline-block;
          font: normal normal normal 24px/1 "Material Design Icons";
          font-size: inherit;
          text-rendering: auto;
          line-height: inherit;
          -webkit-font-smoothing: antialiased;
          background: none;
          transform: none;
        }
      }

      .collapsed {
        &::after {
          content: "\f0140";
        }
      }
    }
  }
  .tab_2 {
    width: 200px;
    max-width: 200px;

    & > .card {
      height: calc(100vh - 140px);
      min-height: calc(100vh - 140px);
      background-color: white;
      border-radius: 5px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 4px;
        scrollbar-width: thin; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ced4da; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        // border: 3px solid orange;  /* creates padding around scroll thumb */
      }

      .card {
        cursor: pointer;
        .header_img {
          margin-right: -0.5px;
          max-height: 110px;
        }
        .services_available {
          background-color: white;
          font-size: 8px;
          position: absolute;
          right: -5px;
          padding: 0px 3px;
          border-radius: 0 0 0 10px;
          top: -1px;
          padding: 2px 10px;
          box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.6);
          .count {
            font-weight: bold;
            padding-right: 2px;
          }
        }
        .card_title {
          font-weight: 500;
          font-size: 12px;
        }
        .card_tagline {
          font-size: 10px;
          color: #74788d;
        }

        &.active {
          border: none;
          box-shadow: -2px 3px 5px -2px #e18479;

          .services_available {
            background-color: #e18479;
            color: white;
          }
        }
      }
    }
  }

  .tab_3 {
    padding: 0;
    > .card {
      min-height: calc(100vh - 140px);
      background-color: transparent;
    }
    .service_title {
      font-weight: 600;
      position: absolute;
      top: -23px;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -4px;
        height: 0px;
        width: 100%;
        border-bottom: 4px solid #e18479;
        border-radius: 50px;
      }
    }
    .service_view_container {
      font-size: 12px;
    }
    .services_tabs {
      background: #ffffff;
      button {
        border: 1px solid #83899354;
        background: transparent;
        font-weight: 100;
        font-size: 12px;
        &:hover,
        &.active {
          background-color: white;
          color: black;
          border-color: black;
          font-weight: 600;
        }
      }
    }
  }
  .tab_4 {
    width: 250px;
    max-width: 250px;
    .card {
      min-height: calc(100vh - 140px);
    }
    .custom_accordion {
      .accordion-body {
        padding: 0px 5px;
      }

      .service-ul {
        margin-bottom: 5px;
        li {
          color: #74788d;
          cursor: pointer;
          font-size: 13px;
          font-weight: 400;
          &.active {
            color: #e18479;
          }
        }
      }

      .accordion-button {
        background: transparent;
        box-shadow: none;
        font-weight: 600;
        padding: 8px 0;
        &::after {
          content: "\f0143";
          display: inline-block;
          font: normal normal normal 24px/1 "Material Design Icons";
          font-size: inherit;
          text-rendering: auto;
          line-height: inherit;
          -webkit-font-smoothing: antialiased;
          background: none;
          transform: none;
        }
      }

      .collapsed {
        &::after {
          content: "\f0140";
        }
      }
    }
  }
  .tab_5 {
    width: 195px;
    max-width: 195px;

    & > .card {
      height: calc(100vh - 140px);
      min-height: calc(100vh - 140px);
      background-color: white;
      border-radius: 5px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 4px;
        scrollbar-width: thin; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ced4da; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
      }
    }
    .custom_accordion {
      .accordion-body {
        padding: 0px 5px;
      }

      .accordion-button {
        background: transparent;
        box-shadow: none;
        font-weight: 600;
        padding: 8px 0;
        &::after {
          content: "\f0143";
          display: inline-block;
          font: normal normal normal 24px/1 "Material Design Icons";
          font-size: inherit;
          text-rendering: auto;
          line-height: inherit;
          -webkit-font-smoothing: antialiased;
          background: none;
          transform: none;
        }
      }
      .collapsed {
        &::after {
          content: "\f0140";
        }
      }
    }
  }
}

#servicce_options_container {
  .form-control {
    font-size: 10px !important;
  }
  .ser_img_drop {
    margin-top: 17px;
    min-height: fit-content;
    .dz-message {
      padding: 3px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      i {
        font-size: 1.5rem;
        padding-right: 8px;
      }
      h6 {
        margin-bottom: 3px;
        font-size: 12px;
      }
    }
  }
  .ser_img {
    height: 46px;
    margin-top: 17px;
    img {
      height: 100%;
    }
  }
  .ser_row_action {
    border: 1px solid #83899354;
    background-color: #eff2f7;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    i {
      cursor: pointer;
    }
  }
  .option_row {
    // background-color: #eff2f7;
    border: 1px solid #dedee1;
    border-radius: 10px;
    .col-form-label {
      font-size: 11px !important;
    }
  }
  .option_delete {
    display: flex;
    align-items: flex-end;
    height: 100%;
    i {
      cursor: pointer;
    }
  }
}

.serInfo_del {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

///////////////////////////////
.services {
  &_images_info {
    .edit_btn_div {
      width: fit-content;
      margin-left: auto;
    }
    .banner {
      img {
        width: 100%;
      }
    }
    .images_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      .img_card {
        height: 75px;
        width: 75px;
        margin-right: 8px;
        margin-top: 10px;
        // background-color: var(--bs-body-bg);
        // margin: 8px;
        border: 1px solid #e2e0e0;
        padding: 5px;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  &_service_info {
    .service_img_container {
      height: 230px;
      position: relative;
      .img_div {
        height: inherit;
        &:after {
          content: " ";
          background-color: #00000069;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        img {
          height: 100%;
          width: 100%;
        }
      }
      p {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        color: white;
      }
    }
  }
  &_options_info {
    .option_div {
      // background-color: var(--bs-body-bg);
      border: 1px solid #e1e1e4;
      border-radius: 5px;
      .service_img_container {
        display: flex;
        flex-wrap: wrap;
        .img_card {
          // width: 100px;
          // height: 100px;
          margin-right: 10px;
          img {
            height: 100px;
            width: 100px;
            border-radius: 10px;
            border: 1px solid #dedee1;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.serviceInfoModal {
  .service_info_li {
    background-color: var(--bs-body-bg);
    .dropzone {
      margin-top: 17px;
      min-height: fit-content;
      .dz-message {
        padding: 12px;
        i {
          font-size: 1.5rem;
          padding-right: 8px;
        }
        h6 {
          margin-bottom: 3px;
          font-size: 12px;
        }
      }
    }
  }
}

.product-info {
  p {
    margin-bottom: 10px;
  }
  ul {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
  }
}

.services_brands {
  .service_img_container {
    justify-content: center !important;
    .img_card {
      img {
        width: 80px !important;
        height: 80px !important;
      }
    }
    .product-name {
      width: 250px;
    }
  }
}

.Shades-div {
  border: 1px solid gainsboro;
  margin-right: 5px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  &.active {
    border: 1px solid #ef4354 !important;
    &:hover {
      color: white;
    }
  }
}