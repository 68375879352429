.profile-div {
  img {
    height: 132px !important;
    width: 132px !important;
  }
  button {
    width: 150px;
  }
}

.max-w-40per {
  max-width: 40%;
}

//Stepper2 styles
.proof-container {
  h5 {
    font-weight: 600;
    opacity: 0.8;
  }
  .image-container {
    position: relative;
    min-height: 250px;
    border: 1px solid black;
    .fileUpload {
      position: absolute;
      bottom: 3px;
      right: 0px;
      img {
        height: 45px;
      }
    }
  }
}

//EXPERTISE

.expertise-card {
  border: 1px solid var(--border-light);
  width: 180px;
  margin-right: 15px;
  min-height: 300px;
  .expertise-header {
    border-bottom: 1px solid var(--border-light);
  }
  .expertise-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  label {
    cursor: pointer !important;
  }
}

//ZipCode
.service_locations {
  .zipcode_header {
    font-weight: 600;
  }

  .zipcode_td {
    border: 1px solid var(--border-light);
    padding: 5px;
    border-radius: 0;

    &.sl_no {
      width: 35px;
      text-align: center;
      line-height: 1.5;
    }
    &:focus {
      border-color: #495057;
    }
  }
}
.Delete_button {
  position: absolute;
  right: 7px;
  top: 37px;
}
.view_button {
  position: absolute;
  right: 10px;
  top: 40px;
}
.view_button_1 {
  position: absolute;
  right: 10px;
  top: 10px;
  img {
    width: 24px;
  }
}

.individual_search {
  .form-control {
    border-radius: 0px !important;
    width: 300px !important;
  }
}
.rating {
  margin-bottom: 12px;
  color: #eac11d;
  i {
    margin: 0 2px;
  }
}

.form-input-hidden {
  border: none !important;

  .overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :hover {
    overflow: visible;
  }
}
