.orders_list {
  .status_container {
    position: relative;
    .status_dot {
      &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        margin-top: 5px;
        border-radius: 50%;
      }
      &.active {
        &:before {
          background: green;
        }
      }
      &.inactive {
        &:before {
          background: red;
        }
      }
    }
  }
}

// Empty Order Style
.empty_order {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;

  i {
    font-size: 100px;
    color: #83899354;
  }
  h4 {
    font-size: 16px;
    font-weight: 700;
    margin-top: -15px;
  }
}

// orderHistory component
.wrapper {
  font-family: "Helvetica";
  font-size: 14px;
}

.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;

  &::before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
  }
}
.StepProgress-item {
  position: relative;
  counter-increment: list;

  &:not(:last-child) {
    padding-bottom: 20px;
  }

  &::before {
    display: inline-block;
    content: "";
    position: absolute;
    left: -30px;
    height: 100%;
    width: 10px;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: -37px;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 50%;
    background-color: #fff;
  }

  &.is-done {
    &::before {
      border-left: 2px solid #2a3042;
    }

    &::after {
      content: "✔";
      font-size: 13px;
      color: #fff;
      text-align: center;
      border: 2px solid green;
      background-color: green;
    }
  }

  &.current::after {
    padding-top: 1px;
    width: 25px;
    height: 25px;
    top: -4px;
    left: -40px;
    font-size: 14px;
    text-align: center;
    color: #2a3042;
    border: 2px solid green;
    background-color: green;
  }
}

.StepProgress strong {
  display: block;
}

/* orderlist component */

.tabs {
  font-size: 12px;
}
.tabs ul li a {
  font-weight: 900;
}
.order-scroll {
  height: 90vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ced4da;
    border-radius: 20px;
  }
}

.Orders_tabs {
  .nav-tabs {
    border-bottom: 2px solid #dee2e6;
    transition: opacity 0.3s ease-in-out;

    .nav-link {
      color: gray !important;
      &:hover {
        border-color: white;
      }
      &:focus {
        border-color: white;
      }
      &.active {
        border-color: white;
        border-bottom: 2px solid #ef4354;
        font-weight: 900;
        color: black !important;
      }
    }
  }
  .order-scroll {
    .order_card {
      @media (min-width: 767px) and (max-width: 999px) {
        padding: 10px;
      }
    }
  }
}
.order-dashboard {
  .nav-tabs {
    border-bottom: 0px;
    transition: opacity 0.3s ease-in-out;

    .nav-link {
      width: 130px;
      &.stylist_not_available {
        width: 170px;
      }
      &.pending_past_orders {
        width: 180px;
      }
      &:hover {
        border-color: transparent !important;
      }

      .card-body {
        padding: 2px;
        position: relative;
        h2 {
          font-size: 20px;
        }
        h6 {
          color: white !important;
        }
        h2 {
          color: white !important;
        }
        .check_mark {
          position: relative;
          i {
            font-size: 20px;
            color: white;
            position: absolute;
            right: 6px;
            bottom: 4px;
          }
        }
        &.bg-today {
          background-color: #b4a7d6 !important;
        }
        &.bg-pending {
          background-color: #ea9999;
        }
        &.bg-past-pending {
          background-color: #537484;
        }
        &.active {
          h6 {
            color: white !important;
          }
          h2 {
            color: white !important;
          }
        }
      }
    }
  }
}

.orderSum_name {
  width: 170px;
}

.order-details-table {
  table {
    th {
      width: 30px !important;
      word-wrap: wrap !important;
      white-space: break-spaces !important;
      font-size: 12px !important;
    }
    td {
      font-size: 12px !important;
      padding: 5px !important;
    }
  }
  .table-bordered {
    td {
      border: 1px solid gainsboro;
    }
  }
}

.additional-charges {
  td {
    border: 1px solid gainsboro;
  }
}

.search-input {
  margin-top: 6px !important;
  margin-bottom: 0px !important;
}
