.artist_quote_info {
  .artist_quote_cards {
    .view_more {
      position: relative;
      .view_badge {
        position: absolute;
        top: 10px;
        right: 4px;
      }
    }
  }
}

.events_information {
  .border {
    border: 1px solid #e1dede !important;
  }
}
.sl_No {
  width: 18px;
  border: 1px solid gray;
  height: 18px;
  text-align: center;
  font-size: 12px;
}
.other_information {
  margin-left: 27px;
}
.customer_name {
  width: 170px;
}
.artist_name {
  // margin-left: 27px;
  border-bottom: 2px solid #ffffff;
}
.artist_orders_list {
  .Orders_tabs {
    .order-scroll {
      height: 65vh !important;
    }
  }
}
.devider {
  border-right: 2px solid black;
  height: 15px;
  position: absolute;
  top: 4px;
  right: 47px;
}
.admin_notes {
  border: 1px solid #dddddd !important;
  .sl_no {
    font-size: 10px;
    font-weight: bold;
    border: 1px solid #c1c1c1 !important;
    padding: 2px;
    height: 16px;
  }
  .modal_image > div {
    &:nth-child(1) {
      width: 60px !important;
      height: 60px !important;
      border: 1px solid #dddddd;
      text-align: center;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
  .adminNotes_description {
    .image {
      margin: 0px !important;
      img {
        width: 50px !important;
        height: 50px !important;
      }
    }
    ul {
      list-style-type: inherit;
    }
  }
}

.Ck_editor_text_box {
  .ck-content .image img {
    width: 200px !important;
    height: 200px !important;
  }
}

.notes_attach_file {
  .dropzone {
    min-height: 80px !important;
    .dz-message {
      padding: 10px !important;
      img {
        width: 40px !important;
        height: 40px !important;
        margin-bottom: 5px !important;
      }
    }
    
  }
}