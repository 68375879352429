.profile-div {
  img {
    height: 150px;
    width: 150px;
  }
  button {
    width: 150px;
  }
}

.max-w-40per {
  max-width: 40%;
}

//Stepper2 styles
.proof-container {
  h5 {
    font-weight: 600;
    opacity: 0.8;
  }
  .image-container {
    position: relative;
    min-height: 250px;
    border: 1px solid black;
    .fileUpload {
      position: absolute;
      bottom: 3px;
      right: 0px;
      img {
        height: 45px;
      }
    }
  }
}
//Add member
.success_add_team {
  border: 1px solid green;
  border-radius: 50px;
  span {
    font-weight: bold;
  }
}
