// .ck.ck-editor__editable_inline > :first-child {
//   height: 200px;
// }

.border-dashed {
  border: 1px dashed #ccc;
}
ul {
  list-style-type: none;
}
ul li input {
  margin-right: 10px;
}
.Product_img {
  height: 200px;
  width: 275px;
}
#product-card {
  height: 350px;
}
.name {
  line-height: 16px;
  font-size: 13px;
  font-weight: 525;
}

.description-list ul li {
  list-style-type: square;
}
.description-list p {
  margin: 0px;
  padding: 0px;
}
.Featured_img {
  padding: 0.25rem;
  background-color: #f8f8fb;
  border: 1px solid #f6f6f6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: 300px;
}
.product-images {
  width: 150px;
  margin: 10px;
  border: 1px solid rgb(207, 203, 203);
}

.variant_container {
  .variant_img_dropzone {
    min-height: fit-content !important;
  }
}
.product-edit-page {
  .nav-tabs {
    border-bottom: 2px solid #dee2e6;
    transition: opacity 0.3s ease-in-out;

    .nav-link {
      color: black;
      border: none;
      &:hover {
        border-color: none !important;
        border-bottom: 2px solid #ef4354;
        border: none;
      }
      &.active {
        background-color: transparent;
        border-color: 1px solid white !important;
        border-bottom: 2px solid #ef4354;
        color: #ef4354;
        font-weight: 900;
      }
    }
  }
}
