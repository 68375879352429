:root {
  --border-light: #adb5bd;
}

.filterMenu {
  .dropdown-menu {
    width: 180px !important;
  }
  .filterIcon {
    box-shadow: none !important;
    &:focus,
    &:active {
      box-shadow: none;
      outline: 0 !important;
    }
  }
  .filter-group {
    .filter-menu-title {
      font-weight: 600;
      text-transform: capitalize;
    }
    ul {
      list-style: none;
      li {
        cursor: pointer;
        margin: 2px 2px;
        &.active {
          background: #eff2f7;
          color: blue;
          &:before {
            content: "\F012C";
            font-family: "Material Design Icons";
            font-style: normal;
            font-weight: bolder;
            margin: 0px 5px 0px 0px;
            text-decoration: none;
          }
        }
        &:hover {
          background: #eff2f7;
          color: blue;
        }
      }
    }
  }
}

.filterCount {
  background: var(--bs-blue);
  border-radius: 50%;
  color: white;
  font-weight: 700;
  height: 20px;
  margin-left: -12px;
  padding-top: 1px;
  text-align: center;
  width: 20px;
}

.max_width_250 {
  max-width: 250px;
}
.cursor-pointer {
  cursor: pointer;
}

.custom_ck_editor {
  .ck-editor__editable_inline {
    min-height: 100px !important;
  }
}

.border-light {
  border: 1px solid #eff2f7 !important;
}

.border-radius-none {
  border-radius: 0 !important;
}

//Custom Country Phone Number Input (with Flag )
.custom_country_phoneNumber {
  padding-left: 5px;
  .PhoneInputCountry {
    margin-right: -35px !important;
  }
  .PhoneInputInput {
    min-height: 38px !important;
    padding-left: 42px !important;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

//Disabled Dropdown
.false__control--is-disabled__control--is-disabled {
  background-color: #eff2f7 !important;

  .false__single-value--is-disabled {
    color: #495057 !important;
  }
}

.phone_number-disabled {
  input,
  select {
    background-color: #eff2f7 !important;
    cursor: not-allowed;
  }
}

.custom_dropdown_sm {
  font-size: 10px;
  > div.select2-selection.dropdown-disabled__control,
  > div.select2-selection.false__control {
    height: 23px !important;
    > div {
      height: inherit;
      padding-top: 0;
    }
  }
  .select2-selection.__dropdown-indicator {
    padding: 0 !important;
  }

  // .select2-selection.false__control--is-disabled,
  // .dropdown-disabled__indicator {
  //   > div {
  //     height: inherit;
  //     padding-top: 0;
  //   }
  // }
}
.divider {
  height: 20px;
  background: #f5f5f8;
}
.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}
.key-del-btn {
  background-color: #fcd9dd !important;
}
.list-column {
  // height: 80vh;
  // overflow-y: scroll;
  height: calc(100vh - 140px);
  min-height: calc(100vh - 140px);
  background-color: white;
  border-radius: 5px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    scrollbar-width: thin; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ced4da; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    // border: 3px solid orange;  /* creates padding around scroll thumb */
  }
}

.modal-img-react {
  .modal-img-size {
    width: 200px;
    height: 70px;
  }
}
.bg-pending {
  background-color: #ea9999 !important;
}

.view_detail {
  .sub_val_name {
    font-size: 14px;
  }
}

.btn-weekdays {
  background-color: white !important;
  color: #ef4354 !important;
  border: 1px solid #ef4354;
  h6 {
    color: #ef4354 !important;
  }
  &:hover {
    border: 1px solid #ef4354 !important;
  }
  &.selected {
    border: 1px solid #ef4354 !important;
    color: white !important;
    background-color: #ef4354 !important;
    h6 {
      color: white !important;
    }
  }
}
.bg-red {
  background-color: red !important;
}
// .selected {
//   background-color: red;
// }
.__react_modal_image__header {
  background-color: transparent !important;
}

.individual_list {
  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Settlement_tabs {
  .nav-tabs {
    border-bottom: 0px;
    transition: opacity 0.3s ease-in-out;

    .nav-link {
      width: 160px;
      &:hover {
        border-color: white !important;
      }
      padding: 0px;
      margin-right: 10px;

      .card-body {
        padding: 2px;
        position: relative;
        h2 {
          font-size: 20px;
        }
        h6 {
          color: white !important;
        }
        h2 {
          color: white !important;
        }
        .check_mark {
          position: relative;
          i {
            font-size: 20px;
            color: white;
            position: absolute;
            right: 6px;
            bottom: 4px;
          }
        }
        &.bg-today {
          background-color: #b4a7d6 !important;
        }
        &.bg-pending {
          background-color: #ea9999;
        }
        &.active {
          h6 {
            color: white !important;
          }
          h2 {
            color: white !important;
          }
        }
      }
    }
  }
}
.pending_payment {
  border: 1px solid #d0d0d0;
  border-radius: 10px !important;
  padding: 5px;
}
.arrow_icon {
  background-color: black;
  border-radius: 48%;
  padding: 6px;
  width: 26px;
  height: 26px;
  text-align: center;
  img {
    width: 13px;
  }
}
@media (max-width: 767px) {
  .toast-warning {
    width: 230px;
  }
  .toast-title {
    font-size: 12px;
  }
  .toast-message {
    font-size: 10px;
  }
  .toast-close-button {
    // display: flex;
    // justify-content: end;
    float: inline-end;
    width: 23px;
    height: 20px;
    line-height: 17px;
  }
}

.font-size-8 {
  font-size: 8px !important;
}
.otp-div {
  .otp_num {
    background-color: #ef4354;
    margin-right: 5px;
    /* padding: 8px; */
    color: white;
    border-radius: 4px;
    font-size: 16px;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 32px;
  }
}
.all_orders_table {
  .form_group {
    margin-bottom: 0px !important ;
  }
}
.react-datepicker {
  font-family: poppins !important;
  // .react-datepicker__day-name {
  //   width: 2.7rem !important;
  // }
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.all_orders_details {
  .page-content {
    margin-bottom: 85px !important;
  }
}

.Order_details_page {
  @media (max-width: 768px) {
    display: none;
  }
  // .Order_details_card {
  //   width: 100%;
  // }
}

.bg-lightGrey {
  background-color: #e5e5e5;
  border: 1px solid #e3dede !important;
}

.sweet-alert {
  z-index: 8000 !important;
}
.btn-go {
  width: 65px !important;
}
.services-card {
  .Hotel_cards {
    min-height: 150px;
    margin-bottom: 14px !important;
    .individual_name {
      max-width: 175px;
    }
  }
}
.ser_row_action {
  border: 1px solid #83899354;
  background-color: #eff2f7;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  i {
    cursor: pointer;
  }
}
.font-size-50 {
  font-size: 50px !important;
}