//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
  > a {
    color: $gray-700;
  }
  + .breadcrumb-item {
    &::before {
      font-family: "Material Design Icons";
      font-weight: bold;
    }
  }
}

.breadcrumb-sub {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  i {
    font-size: 28px;
    padding-right: 10px;
    padding-top: 4px;
    color: black;
  }
  .breadcrumb-item {
    > a {
      color: black;
      font-size: 16px !important;
      font-weight: bold;
    }
    + .breadcrumb-item {
      padding-right: 0.5rem;
      padding-left: 0px;
      &::before {
        font-family: "Material Design Icons";
        font-weight: bold;
        // float: right;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 16px;
      }
    }
  }
}
