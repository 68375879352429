.Available_provider_container {
  .profile_images {
    img {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      border: 3px solid #ffffff;
      margin-left: -8px;
    }
  }
}
