.view-users {
    .view_detail {
      border-left: 2px solid var(--bs-gray-300);
    }
    .hr {
      border-bottom: 2px solid var(--bs-gray-300);
    }
  }
  #biography {
    .ck-content {
      min-height: 200px !important;
    }
  }
  